$primary: #998357;
$secondary: #4f4825;
$white: #fff;
$grey: #383838;
$darkGrey: #42484d;
$danger: #e02e2e;
$danger2: #d3465a;
$backgroundPrimary: #141313;
$backgroundSecondary: lighten($backgroundPrimary, 4);
// $backgroundTertiary: lighten($backgroundPrimary, 18);
$backgroundTertiary: #363636;

$secondary0: #221d1f;
$secondary1: #5a5a5a;

$font: 'Open Sans';

$textGrey: #9b9b9b;
$textWhite: #fff;
$textBlack: #000;

$asideWidth: 300px;
$mainWidth: 720px;

$editModalWidth: 760px;

$iconFillColor: #fff;
